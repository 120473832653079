import { Marqy } from 'marqy'

import { SanityMarquee } from '@data/sanity/queries/types/modules'

import Photo from '@components/photo'
import ProductCard from '@blocks/shop/product-card'

type MarqueeProps = SanityMarquee

const Marquee = ({ items, speed, reverse, pausable }: MarqueeProps) => {
  if (items.length === 0) {
    return null
  }

  return (
    <Marqy
      speed={speed}
      direction={reverse ? 'right' : 'left'}
      pauseOnHover={pausable}
    >
      <div className="flex">
        {items.map((item) => {
          switch (item._type) {
            case 'simple':
              return (
                <span
                  key={item._key}
                  className="block p-4 text-5xl leading-none"
                >
                  {item.text}
                </span>
              )

            case 'photo':
              return (
                <div
                  key={item._key}
                  className="p-4 w-[calc(80vw-2rem)] max-w-[28rem]"
                >
                  <Photo image={item.photo} hasPlaceholder={false} />
                </div>
              )

            case 'product':
              return (
                <div
                  key={item._key}
                  className="p-4 flex flex-col -my-px mx-0 w-[calc(80vw-2rem)] max-w-[28rem]"
                >
                  <ProductCard
                    className="flex-1"
                    product={item.product}
                    hasVisuals
                    showThumbnails
                    showPrice
                    showQuickAdd
                  />
                </div>
              )
          }
        })}
      </div>
    </Marqy>
  )
}

export default Marquee
